import React from 'react';
import {VBMultiLine} from '../connect';
import {VBFileUpload} from '../connect';
import {VBRadios} from '../connect';
import {VBStepButton} from '../connect';

class Step5 extends React.Component {
    render() {
        if(this.props.init_load){
            return null;
        } else{
        return <div class="step-section" data-step="5" style={this.props.step == 5 ? {"display":"block"} : {"display":"none"}}>
            <div class="input-grouping">
                <div class="inputs">
                    <h3>Övriga frågor</h3>
                    <VBRadios  
                        ad_data={this.props.ad_data} 
                        title="Hur vill du signera kontraktet?" 
                        name="signing_method" 
                        options={
                            [{
                                "id": "digital",
                                "label": "Digitalt"
                            },
                            {
                                "id": "paper",
                                "label": "Papper"
                            }]
                        } />
                    <VBRadios  ad_data={this.props.ad_data} name="grot_included" boolMode={true} title="Vill du sälja GROT?" options={
                                    [{
                                        "id": "true",
                                        "label": "Ja"
                                    },
                                    {
                                        "id": "false",
                                        "label": "Nej"
                                    },
                                    {
                                        "id": "null",
                                        "label": "Vet ej"
                                    }]
                                } />
                    <VBRadios  ad_data={this.props.ad_data} title="Är skogen certifierad?" name="cert" options={
                                [{
                                    "id": "PEFC",
                                    "label": "PEFC"
                                },
                                {
                                    "id": "FSC",
                                    "label": "FSC"
                                },
                                {
                                    "id": "both",
                                    "label": "Dubbel"
                                },
                                {
                                    "id": "no",
                                    "label": "Nej"
                                },
                                {
                                    "id": "donno",
                                    "label": "Vet ej"
                                }]
                            } />
                    <div style={this.props.hidden.indexOf("standing_forest") > -1 ? {display:"none"} : {display:""}}>
                        <VBRadios  ad_data={this.props.ad_data} hidden={this.props.hidden} name="standing_forest" boolMode={true} title="Är detta redan avverkat?" options={
                                [{
                                    "id": "false",
                                    "label": "Ja"
                                },
                                {
                                    "id": "true",
                                    "label": "Nej"
                                }]
                            } />
                    </div>
                </div>
                <div class="inputs-info">
                <p>
                <b>Kontrakt: </b><br/> Om du får in anbud som du är nöjd med och väljer köpare på Virkesbörsen, tar vi fram ett avtalsförslag som du kan signera (digitalt med BankID eller papperskontrakt, fysiskt). Det går lika bra att skriva ett eget avtal med köparen i efterhand också. Notera tar du in och väljer anbud utanför Virkesbörsen kan vi INTE hjälpa dig med avtalet.<br/><br/>

                <b>Vill du sälja GROT?</b><br/>
                Om du väljer Ja här så får du även ett pris på biobränslet som faller ut när du avverkar. Vill du inte sälja biobränslet i samband med din avverkning väljer du nej.<br/><br/>

                <b>Är skogen certifierad?</b><br/>
                Om din skog är certifierad välj då antingen PEFC, FSC alternativt dubbel om du har båda.
                </p>
                <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
            <div class="input-grouping">
                <div class="inputs">
                    <h3>Övrig information</h3>
                    <VBMultiLine  ad_data={this.props.ad_data} name="comments" />  
                </div>
                <div class="inputs-info">
                    <p>
                        <b>Övrig information:</b><br/> Här anger du all övrig information som är bra för köparen att känna till. Detta kan exempelvis vara vilka bilvägar man får köra på, kod till vägbom, om du önskar förskott, om skogen är snitslad/bandad eller annan viktig information som hjälper köparen. Om det inte finns någon övrig information så lämna denna ruta tom.
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
            <div class="input-grouping">
                <div class="inputs">
                    <h3>Bilagor</h3>
                    <VBFileUpload  toggle_loading={this.props.toggle_loading} ad_data={this.props.ad_data} btnText="Ladda upp bilagor" name="attachments" />
                </div>
                <div class="inputs-info">
                    <p>
                        <b>Ladda upp bilagor:</b><br/> Här kan du ladda upp samtliga bilagor som rör den aktuella avverkningen/gallring. Exempelvis skogsbruksplan, karta, villkor, stämplingslängder, avverkningsanmälan etc.
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
            <div class="btn-container">
                <div class="left">
                    <VBStepButton text="Föregående" increment={-1} />
                </div>
                <div class="right">
                    <button onClick={() => this.props.visible_ad_draft()} class="btn blue large dash">Spara utkast</button>
                    <VBStepButton text="Nästa" increment={1} ad_data={this.props.ad_data} />
                </div>
			</div>
            
        </div>;
    }}
}

export default Step5