import React, { useState, useEffect } from "react";

const BankContactPerson = ({ person, static_path }) => {
  const styles = {
    mainDiv: {
      marginBottom: "20%",
      backgroundColor: "rgb(200 213 243)",
      // backgroundColor: "rgb(249 205 168)",
    },

    innerDiv: {
      width: "40%",
      paddingBottom: "40%",
      borderRadius: "50%",
      top: "44%",
      overflow: "hidden",
      border: "3px solid white",
    },

    img: {
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      objectFit: "cover",
    },
    cardTextWarper: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    infoCard: {
      height: "100%",
    },
  };

  const profileImage = (per) => {
    if (per.gender === 0) {
      // 0 === "FEMALE"
      if (per.img) {
        return per.img;
      } else {
        return static_path + "img/other/user-female.svg";
      }
    } else if (per.gender === 1) {
      // 1 === "MALE"
      if (per.img) {
        return per.img;
      } else {
        return static_path + "img/other/user-male.svg";
      }
    } else {
      if (per.img) {
        return per.img;
      } else {
        return static_path + "img/other/user.svg";
      }
    }
  };
  useEffect(() => {});

  return (
    <div className="container">
      <div className="row d-flex justify-content-center flex-wrap flex-md-wrap card-container" id="card-container">
        {/* cards */}
        {person && person.length > 0 ? (
          person.map((per) => (
            <div className="col-12 col-sm-9 col-md-6 col-lg-4 px-3 px-sm-2 px-md-1 mb-3">
              <div className="card m-1 m-sm-3" style={styles.infoCard} >
                <div
                  className="d-flex text-center justify-content-center"
                  style={styles.mainDiv}
                >
                  <div
                    className={"text-center position-relative bg-white"}
                    style={styles.innerDiv}
                  >
                    <img
                      className={
                        per.img
                          ? "position-absolute"
                          : "position-absolute svg-grey px-2 pt-3 "
                      }
                      src={profileImage(per)}
                      alt="Card image cap"
                      style={styles.img}
                    />
                  </div>
                </div>
                <div className="card-body text-truncate">
                  <div className="mt-4 d-flex align-items-start flex-column text-truncate">
                    {per.link ? (
                      // This is the card with just the links from the banks with no other information of the people 
                      <div className="w-100">
                        <div className="d-flex justify-content-center">
                          <h4
                            className="pb-0 mb-1 fw-bolder"
                            style={styles.cardTextWarper}
                          >
                            {per.bank_name}
                          </h4>
                        </div>
                        <div className="d-flex justify-content-center px-4 my-5">
                          <img
                            className="img-fluid px-md-5"
                            src={per.bank_logo}
                            alt="Card image cap"
                            style={{ maxHeight: "40px" }}
                          />
                        </div>
                        <div className="my-1 p-3 text-center">
                          <img
                            className="icon-sm p-0 me-2"
                            src={static_path + "img/icon-open/fast-email.svg"}
                          />
                          <small>
                            <a href={per.link} target="_blank">
                            Hitta kontakt hos banken
                            </a>
                          </small>
                        </div>
                      </div>
                    ) : (
                      // This is the card content with information of the people 
                      <div className="w-100">
                        <div className="d-flex justify-content-center">
                          <h4
                            className="pb-0 mb-1 fw-bolder"
                            style={styles.cardTextWarper}
                          >
                            {per.name + " " + per.last_name}
                          </h4>
                        </div>
                        <div className="d-flex justify-content-center">
                          <h6
                            className="pb-0 fw-light"
                            style={styles.cardTextWarper}
                          >
                            {per.title}
                          </h6>
                        </div>

                        <div className="d-flex justify-content-center px-3 my-3">
                          <img
                            className="img-fluid px-5"
                            src={per.bank_logo}
                            alt="Card image cap"
                            style={{ maxHeight: "40px" }}
                          />
                        </div>

                        <div className="d-flex justify-content-center">
                          <div className="">
                            <div className="my-1">
                              <small className="pb-0">
                                <img
                                  className="icon-sm p-0 me-2"
                                  src={
                                    static_path + "img/icon-open/fast-email.svg"
                                  }
                                />
                                <a href={"mailto:" + per.email}>{per.email}</a>
                              </small>
                            </div>

                            <div className="">
                              <small className="pb-0">
                                <img
                                  className="icon-sm p-0 me-2"
                                  src={static_path + "img/icon-open/phone.svg"}
                                />
                                <a href={"tel:" + per.phone}>{per.phone}</a>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex justify-content-center">
            <p>No match found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankContactPerson;
