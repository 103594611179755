import React from 'react';
import VBLeaflet from '@virkesborsen/vb-js-commons/lib/components/vbLeaflet';
import VBDropdown from '@virkesborsen/vb-js-commons/lib/components/new/vbDropdown';
import lang_obj from './price-map-translations';

class PriceMap extends React.Component {
    constructor(props){
        super(props);

        this.state={
            core_data: [],
            leaflet_data: [],
            currency: this.props.language_code == 'se' ? 'SEK' : this.props.language_code == 'no' ? 'NOK' : this.props.language_code == 'pl' ? 'PLN' : 'EUR',
            treeSort: 'soft_timber'
        }
    }

    handleInputChange(value, name) {
        this.setState({ [name]: value });

        setTimeout(() => { this.updateMapData() }, 100)
    }

    selectCountryName(country) {
        if(lang_obj[country] && lang_obj[country][this.props.language_code.toUpperCase()]) {
            return lang_obj[country][this.props.language_code.toUpperCase()]
        } else {
            return country
        }        
    }

    updateMapData() {
        let data = [];

        Object.entries(this.state.core_data).forEach(([name, values]) => {
            let country = {}
            country.name = this.selectCountryName(name);
            country.coords = values.coords
            country.currency = this.state.currency
            country.price = values.prices[this.state.treeSort][this.state.currency]
            data.push(country)
        })

        this.setState({ leaflet_data: data });
    }

    markerFunc(item, container) {
        L.popup({autoClose:false, closeButton:false, closeOnClick: false}).setLatLng(item.coords).setContent('<p class="my-0 text-center"><b>'+item.name+'</b><br />'+item.currency+' <span class="fw-bold blue h4">'+item.price+'</span></p>').addTo(container);
    }

    loadMap = () => {
        return(
            <VBLeaflet className="shadow rounded" mapOptions={{maxZoom: 7, minZoom: 4}} setView={[60.591688, 16.962891]} setZoomView={4} basemapName="Mapbox Plain" markers={this.state.leaflet_data} markerFunc={this.markerFunc}/>
        )
    } 

    componentDidMount() {
        // Get the prices from the main prices.json file
        fetch('https://treebula-bucket.s3.eu-west-1.amazonaws.com/current/prices.json').then(resp => resp.json()).then(data => { 
            this.setState({ core_data: data });
            setTimeout(() => { this.updateMapData() }, 100)
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-12 my-4" style={{padding: "0 1.5rem"}}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-4 px-0 px-md-4">
                            <p className="h4 orange me-md-3">{this.props.trans.wood_assortment}:</p>
                            <VBDropdown initial={this.state.treeSort} options={this.props.lists.treesort} onChange={(e) => {this.handleInputChange(e.value, 'treeSort');}} />
                        </div>
                        <div className="col-12 col-md-4 px-0 px-md-4 mt-2 mt-md-0">
                            <p className="h4 orange me-md-3">{this.props.trans.currency}:</p>
                            <VBDropdown initial={this.state.currency} options={this.props.lists.currencies} onChange={(e) => {this.handleInputChange(e.value, 'currency');}} />
                        </div>
                    </div>
                </div>
                {this.state.leaflet_data.length > 0 ? <div className="col-12">
                    <div style={{height: '500px'}}>
                        {this.loadMap()}
                    </div>
                </div> : null }
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8 text-center">
                        <p className="fs-sm blue pt-4">* {this.props.trans.copy_price_calc}</p>
                        <p className="fs-sm blue">** {this.props.trans.copy_sources}</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PriceMap;