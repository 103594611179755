import React from 'react';
import VBInput from "@virkesborsen/vb-js-commons/lib/components/vbInput";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/vbDropdown";
class VBCalculateInterest extends React.Component {
    constructor(){
        super();
        this.state={
            money: 0,
            years: 0,
            bank_interest: 0,
            bank_name: ""
        }
    }

    formatNumber(num){
        if(num){
            return Math.round(num).toLocaleString('sv-SE');
        } else{
            return 0;
        }
        
    }


    render() {
    let new_sum = false;
    if(this.state.money && this.state.bank_interest && this.state.years){
        new_sum =this.state.money * (((this.state.bank_interest/100) + 1)**this.state.years);
    }
    

    return <div className="vb-calculate-interest">
        <h1>Beräkna ränta</h1>
        <div className="inputs">
            <VBInput 
                errors={{}} 
                inputType="number" 
                ad_data={{}} 
                name="money" 
                disable_ad_data={true} 
                title="Insättning" 
                suffix=" kr"
                onChangeFunc={(val) => {this.setState({"money": Number(val)})}}
            />

            <VBDropdown 
                ad_data={{}} 
                title="Antal år" 
                name="years" 
                disable_ad_data={true} 
                valueChange={(val, lbl) => { this.setState({"years": val}); }} 
                options={[
                    {"id":1,"label": "1 år"},
                    {"id":2,"label": "2 år"},
                    {"id":3,"label": "3 år"},
                    {"id":4,"label": "4 år"},
                    {"id":5,"label": "5 år"},
                    {"id":6,"label": "6 år"},
                    {"id":7,"label": "7 år"},
                    {"id":8,"label": "8 år"},
                    {"id":9,"label": "9 år"},
                    {"id":10,"label": "10 år"}
                ]} 
            />
            <VBDropdown 
                ad_data={{}} 
                title="Välj bank" 
                name="bank" 
                disable_ad_data={true} 
                valueChange={(val, lbl) => { this.setState({"bank_name": lbl,"bank_interest": Number(val.replace(",", "."))}); }} 
                options={this.props.banks} 
            />
        </div>
        {new_sum ? 
        <div className="results">
            <div>
                <h2>Resultat</h2>
                <p>Hos {this.state.bank_name} efter {this.state.years} år</p>
                <h2 className="blue">{this.formatNumber(new_sum)} kr</h2>
            </div>
            <div>
                <h2>Ränta</h2>
                <p>Räntan blir då {this.formatNumber(new_sum - this.state.money)} kr, Notera dock att detta är listräntan och räntan kan förändras</p>
                <h2 className="blue">{this.formatNumber(new_sum - this.state.money)} kr</h2>
            </div>
        </div>
        :null}
        
    </div>;
    }
}

export default VBCalculateInterest;