import React from 'react';

class ProfileAdListings extends React.Component {
    constructor(props){
        super(props);

        this.state={
            ads: this.props.ads.ads,
            bids: this.props.ads.bids,
            opened: this.props.ads.opened,
            filter_year: 'all',
            filter_ads: 'all'
        }
    }

    filterComparisonGenerator(value) {
        let comparison_func = () => true;
        if(value=="all"){
            comparison_func = () => true
        }
        else if(value == 'draft'){
            comparison_func = (ad) => ad.state <= 15
        }
        else if(value == 'wfr'){
            comparison_func = (ad) => ad.state == 30 || ad.state == 35
        }
        else if(value == 'no_bid'){
            comparison_func = (ad) => (ad.state == 40 && ad.has_ended && !ad.has_bids) || ad.state == 42
        }
        else if(value == 'is_over'){
            comparison_func = (ad) => ad.state == 40 && ad.has_ended
        }
        else if(value == 'live'){
            comparison_func = (ad) => ad.state == 40 && !ad.has_ended
        }
        else if(value == 'no_bid_selected'){
            comparison_func = (ad) => ad.state == 44
        }
        else if(value == 'bid_selected'){
            comparison_func = (ad) => ad.state >= 50
        }
        /*else if(value == 'won' || value == "lost"){
            comparison_func = (ad) => ad.state >= 50 && ad.winner
        }
        else if(value == 'no bid selected'){
            comparison_func = (ad) => ad.state == 44
        }
        else if(value == 'lost'){
            comparison_func = (ad) => ad.state == 40 && !ad.winner
        }*/

        return comparison_func
    }

    filterChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        let comparison_func = this.filterComparisonGenerator(value)

        const set = name.replace("filter_", "");

        let result = {
            'bids': {
                'on_going': this.state.bids.on_going,
                'won': this.state.bids.won,
                'lost': this.state.bids.lost
            }
        }

        if(this.props.ads.hasOwnProperty(set)){
            let year_filter = this.props.ads[set].filter((ad) => ad.year == this.state.filter_year || this.state.filter_year == 'all')
            result[set] = year_filter.filter(comparison_func)
        } else if (set == "year") {
            Object.keys(this.props.ads).forEach((key) => {
                if(key != 'bids') {
                    let year_filter = this.props.ads[key].filter((ad) => ad.year == value || value == 'all')
                    result["filter_" + key] = "all"
                    result[key] = year_filter.filter(comparison_func)
                } else {
                    Object.keys(this.props.ads[key]).forEach((bidkey) => {
                        let year_filter = this.props.ads.bids[bidkey].filter((ad) => ad.year == value || value == 'all')
                        result.bids[bidkey] = year_filter
                    })
                }
            })
        }

        result[name] = value;
        this.setState(result);
    }

    filterOption(value, context) {
        let evalFunc = this.filterComparisonGenerator(value)
        const set = context.replace("filter_", "");
        let year_filter = this.props.ads[set].filter((ad) => ad.year == this.state.filter_year || this.state.filter_year == 'all')
        //let adStates = year_filter.reduce((acum, ad) => {acum.push(ad.state); return acum;}, []);

        for(let ad of year_filter) {
            if(evalFunc(ad)){
                return true;
            }
        }
        return false;
    }

    loadItem(value) {
        return (
            <div className="bg-white box-shadow bs-light my-3 mx-md-4">
                <div className="row p-4">
                    <div className="col-9">
                        <h4>{value['betekning'] ? value['betekning']+' | ' : null }{value['muni'] != "None" ? value['muni']+", " : null} {value['region'] ? value['region'] : 'Utkast'}</h4>
                    </div>
                    <div className="col-3 ps-0">
                        <h4 className="text-end">#{value['id']}</h4>
                    </div>
                    <div className="col-12">
                        <p className="m-0">
                        {value['total_volume'] ? <span className="orange border-end border-3 border-orange me-2 pe-2">{value['total_volume']} m3fub </span> : null }
                        <span className="border-end border-3 border-orange me-2 pe-2">{value['type']} </span>
                        {value['arrangement'] ? value['arrangement'] : null }
                        </p>
                        <p className="m-0 mt-2 fw-bold">{value['status']}</p>
                        {value['contract_pdf'] || value['contact'] ?
                            <div className="row">
                                {value['contact'] ? <div className="col-12 col-md-6">
                                    <h5 className="mb-0 mt-2 blue">{value['contact'].label}</h5>
                                    <p><b>{value['contact'].name}</b><br />
                                    Email: <a href={"mailto:"+value['contact'].email} className="fw-bold">{value['contact'].email}</a><br />
                                    Telefon: <a href={"tel:"+value['contact'].phone} className="fw-bold">{value['contact'].phone}</a></p>
                                </div>: null}
                                {value['contract_pdf'] ? <div className="col-12 col-md-6 d-flex align-items-end"><a className="btn btn-blue w-100" href={value['contract_pdf']} target="_blank">Ladda ner kontrakt</a></div>: null}
                            </div>
                        : null}
                    </div>
                </div>

                {this.loadButtons(value)}
            </div>
        )
    }

    loadButtons(value) {
        if (value['has_bids'] === true) {
            return (
                <div className="row">
                    <div className="col-12 col-md-6 pe-md-0">
                        <a href={this.adUrlDetails(value['id'], 'ad', value['state'])} class="d-flex justify-content-center bg-orange white py-2" target="_blank">{value['status'] == "Fullfölj virkesannons" ? "Fullfölj annons" : "Se annons"}</a>
                    </div>
                    <div className="col-12 col-md-6 ps-md-0">
                        <a href={this.adUrlDetails(value['id'], 'bid', value['state'])} class="d-flex justify-content-center bg-blue white py-2" target="_blank">{value['status'] == "Du ska nu välja köpare" ? 'Välja köpare' :'Se anbud'}</a>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row">
                    <div className="col-12">
                        <a href={this.adUrlDetails(value['id'], 'ad', value['state'])} class="d-flex justify-content-center bg-orange white py-2" target="_blank">{value['status'] == "Fullfölj virkesannons" ? "Fullfölj virkesannons" : "Se annons"}</a>
                    </div> 
                </div>
            )
        }
    }

    adUrlDetails(id, btn, state) {
        let url;
        if (btn == 'bid') {
            url = "/auctions/"+id+"/bids/";
        } else {
            url = state === 15 ? "/auctions/"+id+"/edit/" : "/auctions/"+id+"/";
        }
        return url
    }

    render() {
    
    return <div className="container">
    <div className="row flex-md-row-reverse">
        <div class="col-12 col-md-4 mb-4">
            <h2 className="orange">Filters</h2>

            <h4 className="mb-1 mt-2">Filtrera på år</h4>
            <select class="vb-input w-100" name="filter_year" onChange={(e) => {this.filterChange(e);}} aria-label="Filter select">
                <option value="all" selected>Visa allt</option>
                {this.props.years.map((value) => {
                    return (<option value={value}>{value}</option>)
                })}
            </select>

            <div className={this.props.ads.ads.length == 0 ? "d-none" : null}>
                <h4 className="mb-1 mt-2">Mina annonser</h4>
                <select class="vb-input w-100" name="filter_ads" value={this.state.filter_ads} onChange={(e) => {this.filterChange(e);}} aria-label="Filter select">
                    <option value='all'>Visa alla annonser</option>
                    {this.filterOption("draft", 'filter_ads') ? <option value="draft">Utkast</option> : null}
                    {this.filterOption("wfr", 'filter_ads') ? <option value="wfr">Väntar på granskning</option> : null}
                    {this.filterOption("live", 'filter_ads') ? <option value="live">Aktiva annonser</option> : null}
                    {this.filterOption("no_bid", 'filter_ads') ? <option value="no_bid">Annonser utan anbud</option> : null}
                    {this.filterOption("is_over", 'filter_ads') ? <option value="is_over">Välj köpare</option> : null}
                    {this.filterOption("no_bid_selected", 'filter_ads') ? <option value="no_bid_selected">Inget anbud valt</option> : null}
                    {this.filterOption("bid_selected", 'filter_ads') ? <option value="bid_selected">Sålda annonser</option> : null}
                </select>
            </div>

            {/*<div className={this.props.ads.bids.length == 0 ? "d-none" : null}>
                <h4 className="mb-1 mt-2">Mina anbud</h4>
                <select class="vb-input w-100" name="filter_bids" value={this.state.filter_bids} onChange={(e) => {this.filterChange(e);}} aria-label="Filter select">
                    <option value='all'>Visa alla annonser</option>
                    {this.filterOption((state) => state == 40, 'filter_bids') ? <option value="live">Aktiva annonser</option> : null}
                    {this.filterOption((state) => state >= 50, 'filter_bids') ? <option value="won">Vunnit</option> : null}
                    {this.filterOption((state) => state == 44, 'filter_bids') ? <option value="no bid selected">Valt inget bud</option> : null}
                    {this.filterOption((state) => state >= 50, 'filter_bids') ? <option value="lost">Förlorat</option> : null}
                </select>
            </div>*/}
        </div>

        <div className="col-12 col-md-8">
            <div className="row">
                <a className={this.props.ads.ads.length == 0 ? "d-none" : null} href="#minaAnnonser" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnnonser">
                    <div className="col-12">
                        <h4 className="border-bottom border-4 border-orange">Alla dina annoser ({this.state.ads.length}/{this.props.ads.ads.length}).</h4>
                        <div className="d-flex align-items-center plus-berger">
                            <div className="rounded bg-blue"></div>
                            <div className="rounded bg-blue"></div>
                        </div>
                    </div>
                </a>
                <div id="minaAnnonser" className={this.props.ads.ads.length == 0 ? "d-none" : "col-12 px-4 py-2 collapse"}>
                    {this.state.ads.map((value) => {
                        return (
                            value['show'] ? this.loadItem(value) : null
                        )
                    })}
                </div>
                
                {/* Here comes the bids ongoing */}
                <a className={this.props.ads.bids.on_going.length == 0 ? "d-none" : null} href="#minaAnbudOngoing" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnbudOngoing">
                    <div className="col-12">
                        <h4 className="border-bottom border-4 border-orange">Pågående anbud ({this.state.bids.on_going.length}/{this.props.ads.bids.on_going.length}).</h4>
                        <div className="d-flex align-items-center plus-berger">
                            <div className="rounded bg-blue"></div>
                            <div className="rounded bg-blue"></div>
                        </div>
                    </div>
                </a>
                <div id="minaAnbudOngoing" className={this.props.ads.bids.on_going.length == 0 ? "d-none" : "col-12 px-4 py-2 collapse"}>
                    {this.state.bids.on_going.map((value) => {
                        return (
                            value['show'] ? this.loadItem(value) : null
                        )
                    })}
                </div>
                
                {/* Here comes the bids won */}
                <a className={this.props.ads.bids.won.length == 0 ? "d-none" : null} href="#minaAnbudWon" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnbudWon">
                    <div className="col-12">
                        <h4 className="border-bottom border-4 border-orange">Köpta annonser ({this.state.bids.won.length}/{this.props.ads.bids.won.length}).</h4>
                        <div className="d-flex align-items-center plus-berger">
                            <div className="rounded bg-blue"></div>
                            <div className="rounded bg-blue"></div>
                        </div>
                    </div>
                </a>
                <div id="minaAnbudWon" className={this.props.ads.bids.won.length == 0 ? "d-none" : "col-12 px-4 py-2 collapse"}>
                    {this.state.bids.won.map((value) => {
                        return (
                            value['show'] ? this.loadItem(value) : null
                        )
                    })}
                </div>

                {/* Here comes the bids no bid selected*/}
                <a className={this.props.ads.bids.not_sold.length == 0 ? "d-none" : null} href="#minaAnbudUnsold" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnbudUnsold">
                    <div className="col-12">
                        <h4 className="border-bottom border-4 border-orange">Annonser som inte blev sålda ({this.state.bids.not_sold.length}/{this.props.ads.bids.not_sold.length}).</h4>
                        <div className="d-flex align-items-center plus-berger">
                            <div className="rounded bg-blue"></div>
                            <div className="rounded bg-blue"></div>
                        </div>
                    </div>
                </a>
                <div id="minaAnbudUnsold" className={this.props.ads.bids.not_sold.length == 0 ? "d-none" : "col-12 px-4 py-2 collapse"}>
                    {this.state.bids.not_sold.map((value) => {
                        return (
                            value['show'] ? this.loadItem(value) : null
                        )
                    })}
                </div>

                {/* Here comes the bids lost */}
                <a className={this.props.ads.bids.lost.length == 0 ? "d-none" : null} href="#minaAnbudLost" data-bs-toggle="collapse" aria-expanded="false" aria-controls="minaAnbudLost">
                    <div className="col-12">
                        <h4 className="border-bottom border-4 border-orange">Förlorade anbud ({this.state.bids.lost.length}/{this.props.ads.bids.lost.length}).</h4>
                        <div className="d-flex align-items-center plus-berger">
                            <div className="rounded bg-blue"></div>
                            <div className="rounded bg-blue"></div>
                        </div>
                    </div>
                </a>
                <div id="minaAnbudLost" className={this.props.ads.bids.lost.length == 0 ? "d-none" : "col-12 px-4 py-2 collapse"}>
                    {this.state.bids.lost.map((value) => {
                        return (
                            value['show'] ? this.loadItem(value) : null
                        )
                    })}
                </div>

                <a className={this.props.ads.opened.length == 0 ? "d-none" : null} href="#oppnadeAnnonser" data-bs-toggle="collapse" aria-expanded="false" aria-controls="oppnadeAnnonser">
                    <div className="col-12">
                        <h4 className="border-bottom border-4 border-orange">Annonser du har öppnat ({this.state.opened.length}/{this.props.ads.opened.length}).</h4>
                        <div className="d-flex align-items-center plus-berger">
                            <div className="rounded bg-blue"></div>
                            <div className="rounded bg-blue"></div>
                        </div>
                    </div>
                </a>
                <div id="oppnadeAnnonser" className={this.props.ads.opened.length == 0 ? "d-none" : "col-12 px-4 py-2 collapse"}>
                    {this.state.opened.map((value) => {
                        return (
                            value['show'] ? this.loadItem(value) : null
                        )
                    })}
                </div>
            </div>
        </div>
    </div>
</div>
    }
}

export default ProfileAdListings;