import React from 'react';
import {VBForestCreator} from '../connect';
import {VBStepButton} from '../connect';

class Step3 extends React.Component {

    constructor(){
        super();
        this.forestCreator = React.createRef();
    }
    
    render() {
        if(this.props.init_load){
            return null;
        } else{
        return <div class="step-section" data-step="3" style={this.props.step == 3 ? {"display":"block"} : {"display":"none"}}>
            <div class="input-grouping">
                <div class="inputs">
                    <h3>Virkesinformation</h3>
                    <VBForestCreator ref={this.forestCreator} ad_data={this.props.ad_data} hidden={this.props.hidden} name="forestWithSections" />
                    <div class="btn-container">
                        <div class="left">
                            <VBStepButton text="Föregående" increment={-1} />
                        </div>
                        <div class="right">
                            <button onClick={() => this.props.visible_ad_draft()} class="btn blue large dash">Spara utkast</button>
                            <VBStepButton text="Nästa"
                            onStepChange={()=>{
                                let resp = this.forestCreator.current.checkforAndAddSection();
                                return resp;
                            }}
                            increment={1}
                            />
                        </div>
                    </div>
                </div>
                <div class="inputs-info">
                    <p>
                    Om du kan, utgå då från din skogsbruksplan, mestadels av denna information finns under avdelningsbeskrivningen. Har du inte en skogsbruksplan kan du ändå fylla i formuläret, behöver du hjälp ring oss på <a href="tel:0046339944">08-339944</a> eller boka en tid <a data-track="true" data-eventName="Clicked boka genomgång" href="https://virkesborsen.typeform.com/to/MwS6W2" target="_blank">här</a> så hjälper vi dig!<br/><br/>
                    <b>Avdelningsnamn:</b> Namnet på den aktuella avdelningen (Exempel avd 34) här är det fritt att kalla avdelningen vad som helst.<br/><br/>
                    <b>Avdelningens areal:</b> Areal på den aktuella avdelningen anges i hektar.<br/><br/>
                    <b>Ståndortsindex:</b> Finns också i skogsbruksplanen, detta fält är inte obligatoriskt.<br/><br/>
                    <b>Trädslagsfördelning (TGL): </b>
                    TGL eller trädslagsblandning anger fördelningen mellan tall, gran, löv eller andra trädslag i din skogsbruksplan. Om det exempelvis står 811 så betyder det att avdelningen har 80% tall, 10% gran och 10% löv. Står det istället X00 så betyder det att det är 100% tall. Notera att summan alltid måste bli 10 eller 100.<br/><br/>
                    Har du några andra trädslag klickar du på ”Lägg till fler trädslag” för att fylla procentandelen för det trädslaget också. <br/><br/>
                    <b>Uttag inkl. Tillväxt:</b> Den volym på den avledning som skall tas ut vid avverkningen eller gallringen står oftast under kolumnen ”Uttag ink tillväxt” i skogsbruksplanen. Vi kan även hjälpa till att ta fram detta. behöver du hjälp ring oss på <a href="tel:0046339944">08-339944</a> eller boka en tid <a data-track="true" data-eventName="Clicked boka genomgång" href="https://virkesborsen.typeform.com/to/MwS6W2" target="_blank">här</a> så hjälper vi dig!<br/><br/>

                    <b>Lägg till avdelning</b> När du fyllt i informationen kan du klicka på nästa. Vill du lägga till fler avdelningar klickar du på Lägg till avdelning och fyller i informationen för nästa avdelning.
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
    </div>;
    }}
}

export default Step3;


