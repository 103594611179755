function verifyExists(elem, data){
    if(data.hasOwnProperty(elem)){
        if(!data[elem]){
          return "Detta måste fyllas i";
        }

      }
      else{
        return"Detta måste fyllas i";
      }
}

function convertDates(date){
    if(!date){
        date = new Date();
    } else{
        date = new Date(date);
    }
    return date.setHours(0,0,0,0);
}

function verifyStepOneDates(elem, data){
    let error = verifyExists(elem, data);
    if(error){
        return error;
    }
    if(elem == "start_time"){
        if(convertDates() > convertDates(data["start_time"])){
            return "Startdatum får inte vara före idag";
        }
    } 
    else if(elem == "end_time"){
        if(convertDates(data["start_time"]) >= convertDates(data["end_time"])){
            return "Slutdatum får inte vara före startdatum";
        }
    } 
    else if(elem == "felling_start_time"){
        if(convertDates(data["end_time"]) > convertDates(data["felling_start_time"])){
            return "Avverkningsperioden får inte börja före annonsperioden är över";
        }
    } 
    else if(elem == "felling_end_time"){
        if(convertDates(data["felling_start_time"]) >= convertDates(data["felling_end_time"])){
            return "Avverkningsperiodens slutdatum kan inte vara före avverkningsperiodens startdatum";
        }
    } else if(elem == "pickup_date"){
        if(convertDates(data["end_time"]) > convertDates(data["pickup_date"])){
            return "Upphämtningsdatum får inte vara före annonsperioden är över";
        }
    }
}

const StepRequired ={
    "1": {
        "type":verifyExists,
        "start_time":verifyStepOneDates,
        "end_time":verifyStepOneDates,
        "felling_start_time":verifyStepOneDates,
        "felling_end_time":verifyStepOneDates,
        "delivery_year":verifyExists,
        "pickup_date":verifyExists,
        "description_of_property":verifyExists
    },
    "2": {
        "felling_application_responsible":verifyExists,
        "muni":verifyExists,
        "map_data":verifyExists
    },
    "3": {
        "forestWithSections":verifyExists
    },
    "4": {
    }, 
    "5": {
        "standing_forest":verifyExists
    }
}

export default StepRequired;