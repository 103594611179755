import React from 'react';
import {VBRadios} from '../connect';
import {VBMultiLine} from '../connect';
import {VBStepButton} from '../connect';

class Step4 extends React.Component {
    
    render() {
        if(this.props.init_load){
            return null;
        } else{
        let yesno = [{
            "id": "true",
            "label": "Ja"
        },
        {
            "id": "false",
            "label": "Nej"
        },
        {
            "id": "null",
            "label": "Vet ej"
        }];
        return <div class="step-section" data-step="4" style={this.props.step == 4 ? {"display":"block"} : {"display":"none"}}>

            <div class="input-grouping">
                <div class="inputs">
                    <h3>Vill du ha någon skogsvård i samband med din avverkning?</h3>
                    <VBRadios  ad_data={this.props.ad_data} boolMode={true} name="ground_preparation" title="Markberedning" options={yesno} />
                    <VBRadios  ad_data={this.props.ad_data} boolMode={true} name="replanting" title="Återplantering" options={yesno} />
                    <VBRadios  ad_data={this.props.ad_data} boolMode={true} name="precleaning_needed" title="Förröjning" hidden={this.props.hidden} options={yesno} />
                </div>
                <div class="inputs-info">
                    <p>
                    <b>Skogsvård: </b>Om du ska utföra någon skogsvård i samband med din avverkning/gallring och vill ha offert på utförandet av skogsvården väljer du Ja på dom åtgärder som du vill få utförda. Då får du prisförslag från eventuella köpare på dessa åtgärder utöver anbud på virket.
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
            <div class="input-grouping">
                <div class="inputs">
                    <VBMultiLine
                    ad_data={this.props.ad_data} 
                    title="Övrig information gällande skogsvård" 
                    name="service_comments" 
                    larger={true}
                    /> 
                </div>
                <div class="inputs-info">
                    <p>
                    <b>Övrig information gällande skogsvård:</b> Här kan du ange hur du vill att förröjningen skall utföras, hur många plantor du vill ska återplanteras eller annan viktig information runt din skogsvård.
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
            <div class="btn-container">
                <div class="left">
                    <VBStepButton text="Föregående" increment={-1} />
                </div>
                <div class="right">
                    <button onClick={() => this.props.visible_ad_draft()} class="btn blue large dash">Spara utkast</button>
                    <VBStepButton text="Nästa" increment={1} />
                </div>
			</div>
        </div>;
    }}
}

export default Step4;