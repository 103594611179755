import React, { Component } from "react";
import { Provider, connect } from "react-redux";
import { takeEvery, takeLatest, select, put, delay } from "redux-saga/effects";

import createStore from "@virkesborsen/vb-js-commons/lib/redux";
//import INTERACTIONS from "@virkesborsen/vb-js-commons/lib/interactions";
import StepRequired from "./stepRequired";
import Cookies from 'js-cookie';

import {CommonComponent} from "./connect";
import {VBInput} from "./connect";
import {VBRadios} from "./connect";
import {VBRadio} from "./connect";
import {VBMultiOption} from "./connect";
import {VBMultiInput} from "./connect";
import {VBForestMapFinalfellingApplicationChooser} from "./connect";
import {VBForestMap} from "./connect";
import {VBForestCreator} from "./connect";
import {VBFileUpload} from "./connect";
import {VBDropdown} from "./connect";
import {VBPhoneInput} from "./connect";
import {VBMultiLine} from "./connect";
import {VBDatePicker} from "./connect";
import {VBOldforestEditor} from "./connect";
import {VBAlert} from "./connect";
import VBCarousel from "@virkesborsen/vb-js-commons/lib/components/vbCarousel";
import VBErrorBoundary from "@virkesborsen/vb-js-commons/lib/components/vbErrorBoundaryHandler";
import {get_ad_data, send_ad_data, get_ad_interactions, get_municipality} from "@virkesborsen/vb-js-commons/lib/api";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import Step5 from "./steps/step5";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import Steps from "@virkesborsen/vb-js-commons/lib/components/steps";
import Login from "./login/login";
import ProfileAdListings from "./profile/ad-listings";
import PurchasePrimakund from './primakund/purchase-primakund';
import Complementinfo from "./login/complementinfo";
import {VBStepButton} from "./connect";

import AdLanding from "./landing/landing";

import VBCalculateInterest from "./forestbank/vbCalculateInterest";
import ForestCatalogue from "./forestbank/ForestCatalogue";
import PriceMap from "./treebula/price-map"

require('es6-promise').polyfill();
require('isomorphic-fetch');
require('./polyfills.js');



function get_interactions(ad, interactions){
  let hidden = [];
  let show = [];
  for (let [name, val] of Object.entries(ad)) {
    if(interactions.hasOwnProperty(name)){

      if(interactions[name].hasOwnProperty([val])){

        let elemInteractions = interactions[name][val];
        hidden = hidden.concat(elemInteractions['hide']);

      }

    }

  }
  return {'hidden': hidden, 'show':show};
}

const exampleReducer = (state = { count: 0 , stepErrors:[], ad_data:{}, ad_interactions: {}, municipality_area:null, init_load:true, show_loading:true, errors: {}, interactions: {'hidden': [], 'show':[]}}, action) => {
  switch (action.type) {
    case "HELLO":
      return Object.assign({}, state, {
        count: state.count + 1
      });

    case 'TOGGLE_LOADING':
        return Object.assign({}, state, {
          show_loading: action.payload.val
        });

    case 'STORE_AD_INTERACTIONS':
        return Object.assign({}, state, {
          ad_interactions: action.payload
        });
    case 'STORE_AD_DATA':
        return Object.assign({}, state, {
          ad_data: {...state.ad_data, ...action.payload},
          init_load: false,
          show_loading: false,
          interactions: get_interactions(action.payload, state.ad_interactions)
        });

    case 'UPDATE_AD_DATA':
        return Object.assign({}, state, {
          ad_data: {...state.ad_data, ...action.payload},
          init_load: false,
          show_loading: false,
          interactions: get_interactions({...state.ad_data, ...action.payload}, state.ad_interactions)
        });
    case 'ERROR':
        return Object.assign({}, state, {
          errors: {...state.errors, ...action.payload}
        });

    case 'REMOVE_ERROR':
        let errs = {...state.errors};
        if(errs.hasOwnProperty(action.payload)){
          delete errs[action.payload]
        }
        return Object.assign({}, state, {
          errors: errs
        });

    case 'SET_STEP':
        return Object.assign({}, state, {
          step: action.payload,
          stepErrors: []
        });
    case 'SET_STEP_ERROR':
      return Object.assign({}, state, {
        errors: action.payload['stepErrors'],
        step: action.payload['step']
      });

    case 'SET_MUNI_NAME':
        return Object.assign({}, state, {
          municipality: action.payload
        });
    case 'SET_MUNI_AREA':
        return Object.assign({}, state, {
          municipality_area: action.payload
        });
    case 'SET_ALERT':
        return Object.assign({}, state, {
          alertMessage: action.payload
        });
    default:
      return state;
  }
};

function* FETCH_AD_DATA(){
  const resp = yield get_ad_data();
  const ad_data = yield resp.json();

  const respInteractions = yield get_ad_interactions();
  const interactions = yield respInteractions.json();
  yield put({type: "STORE_AD_INTERACTIONS", payload: interactions});

  yield put({type: "STORE_AD_DATA", payload: ad_data});

  if(ad_data['muni']){
    const resp = yield get_municipality(ad_data['muni']);
    const muni_data = yield resp.json();
    yield put({type:'SET_MUNI_NAME', payload: muni_data.properties.name});
    yield put({type: "SET_MUNI_AREA", payload: muni_data})
  }
}

const exampleSaga = function*() {
  yield takeEvery("HELLO", function*() {
    console.log("exampleSaga acting on HELLO");
    yield delay(1000);
    const state = yield select();
    yield put({ type: "SAGA_ACTION", payload: { count: state.hello.count } });
  });

  yield takeEvery("STEP_CHANGE", function*(data){
    const state = yield select();

    if(Object.keys(state.errors.errors).length === 0 && state.errors.errors.constructor === Object){
      let error = false;
      let stepErrors = {}
      let currStep;
      for(let i=1;i<data.payload;i++){
        let curr_set_data = StepRequired[i.toString()];
        Object.keys(curr_set_data).forEach(function(elem){

          if(state.interactions.interactions['hidden'].indexOf(elem) == -1){
             let errorMsg = curr_set_data[elem](elem, state.ad_data.ad_data);
             if(errorMsg){
              stepErrors[elem] = errorMsg;
              error = true;
             }
          }

        });
        if(error){
          currStep = i;
          break;
        }
      }
      try{
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch(err){
        //Do nothing, not important scroll
      }
      
      if(!error){
        yield put({ type: "SET_STEP", payload: data.payload });
        yield FETCH_AD_DATA();
        Cookies.set("curr_step", data.payload);
        mixpanel.track("Step change - Ad form: " + data.payload.toString(), {"goto step": data.payload});
      } else{
        yield put({ type: "SET_STEP_ERROR", payload: {'stepErrors':stepErrors, "step": currStep}});
      }
    }
  });

  yield takeEvery("INITAL_FETCH_AD_DATA", function*(){
    try{
      yield FETCH_AD_DATA();
      let curr_step = Cookies.get("curr_step");
      if(curr_step){
        yield put({type: "STEP_CHANGE", payload: curr_step});
      } else{
        yield put({type: "STEP_CHANGE", payload: 1});
      }
    
    } catch(e){
      console.log(e);
    }
  });

  yield takeEvery("SEND_AD_DATA", function*(data){
    let resp = yield send_ad_data(data.payload);
    //Track click in MP with full formData, dont send full area to mp
    for (let key in data.payload){
      if(data.payload.hasOwnProperty(key)){
        let mp_data = {}
        mp_data[key] = data.payload[key];
        if(key == "area"){
          mixpanel.track("Ad form - " + key, {"area": "done"});
        } else{
          mixpanel.track("Ad form - " + key, mp_data);
        }
      }
    }
    
    if(resp.ok){
      yield put({type:"UPDATE_AD_DATA", payload:data.payload});
      yield put({type:"REMOVE_ERROR", payload: Object.keys(data.payload)[0]})
    }
  });

  yield takeEvery("SET_MUNI", function*(data){
    yield put({type:'SET_MUNI_NAME', payload: data.payload["name"]});

    const resp = yield get_municipality(data.payload["id"]);
    const muni_data = yield resp.json();
    yield put({type: "SET_MUNI_AREA", payload: muni_data})
  });

  yield takeEvery("VISIBLE_AD_DRAFT", function*(){
    send_ad_data({"visible_draft": true});
    let ad_id = Cookies.get("ad_id");
    mixpanel.track("Made draft visible", {"ad_id": ad_id});
    yield put({type:"SET_ALERT", payload: "Ditt utkast är sparat"});
  });
};


const store = createStore({
  additionalReducers: {
    hello: exampleReducer,
    interactions: exampleReducer,
    ad_data: exampleReducer,
    init_load: exampleReducer,
    show_loading: exampleReducer,
    errors: exampleReducer,
    step: exampleReducer,
    municipality: exampleReducer,
    stepErrors: exampleReducer,
    ad_interactions: exampleReducer,
    municipality_area: exampleReducer,
    alertMessage: exampleReducer
  },
  additionalSagas: [exampleSaga],
  preloadedState: {hello: {count: 0}, errors:{}, stepErrors:[], alertMessage:"", ad_data:{}, init_load: true,municipality_area:null, ad_interactions: {}, interactions: {'hidden': [], 'show':[]}, step:1, municipality:""}
});

if(window.location.pathname.indexOf("/auctions/create") > -1){
  store.dispatch({type: "INITAL_FETCH_AD_DATA", payload: {}});
}


const ConnectedSteps = connect(
  state => ({init_load: state.init_load.init_load, step:state.step.step}),
  dispatch => ({
    change_step: (step) => dispatch({type: "STEP_CHANGE", payload:step}),
    send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data})
  })
)(Steps);

const ConnectedStep1 = connect(
  state => ({ad_data: state.ad_data.ad_data, init_load: state.init_load.init_load, hidden: state.interactions.interactions['hidden'], step:state.step.step}),
  dispatch => ({
    addError: (data) => dispatch({type: 'ERROR', payload: data}),
    removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
    send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
    setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt}),
    visible_ad_draft: () => dispatch({type: "VISIBLE_AD_DRAFT"})
  })
)(Step1);

const ConnectedStep2 = connect(
  state => ({ad_data: state.ad_data.ad_data, init_load: state.init_load.init_load, hidden: state.interactions.interactions['hidden'], step:state.step.step, municipality: state.municipality.municipality}),
  dispatch => ({
    addError: (data) => dispatch({type: 'ERROR', payload: data}),
    removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
    toggle_loading: (val) => dispatch({type:"TOGGLE_LOADING", payload:{val:val} }),
    set_muni: (id, name) => dispatch({type:"SET_MUNI", payload:{id:id, name:name}}),
    send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
    setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt}),
    visible_ad_draft: () => dispatch({type: "VISIBLE_AD_DRAFT"})
  })
)(Step2);

const ConnectedStep3 = connect(
  state => ({ad_data: state.ad_data.ad_data, init_load: state.init_load.init_load, hidden: state.interactions.interactions['hidden'], step:state.step.step}),
  dispatch => ({
    addError: (data) => dispatch({type: 'ERROR', payload: data}),
    removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
    send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
    setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt}),
    visible_ad_draft: () => dispatch({type: "VISIBLE_AD_DRAFT"})
  })
)(Step3);

const ConnectedStep4 = connect(
  state => ({ad_data: state.ad_data.ad_data, init_load: state.init_load.init_load, hidden: state.interactions.interactions['hidden'], step:state.step.step}),
  dispatch => ({
    addError: (data) => dispatch({type: 'ERROR', payload: data}),
    removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
    send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
    setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt}),
    visible_ad_draft: () => dispatch({type: "VISIBLE_AD_DRAFT"})
  })
)(Step4);

const ConnectedStep5 = connect(
  state => ({ad_data: state.ad_data.ad_data, init_load: state.init_load.init_load, hidden: state.interactions.interactions['hidden'], step:state.step.step}),
  dispatch => ({
    addError: (data) => dispatch({type: 'ERROR', payload: data}),
    removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
    toggle_loading: (val) => dispatch({type:"TOGGLE_LOADING", payload:{val:val} }),
    send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
    setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt}),
    visible_ad_draft: () => dispatch({type: "VISIBLE_AD_DRAFT"})
  })
)(Step5);

const ConnecteVBLoadingScreen = connect(
  state => ({show_loading: state.show_loading.show_loading}),
  dispatch => ({
    send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data})
  })
)(VBLoadingScreen);

const ConnectedAdLanding = connect(
  state => ({}),
  dispatch => ({
    send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data})
  })
)(AdLanding);


export default {
  "provider": Provider,
  'store': store,
  'CommonComponent': CommonComponent,
  'VBInput': VBInput,
  'VBRadios': VBRadios,
  'VBRadio': VBRadio,
  'VBMultiOption': VBMultiOption,
  'VBMultiInput': VBMultiInput,
  'VBForestMapFinalfellingApplicationChooser': VBForestMapFinalfellingApplicationChooser,
  'VBForestMap': VBForestMap,
  'VBForestCreator': VBForestCreator,
  'VBFileUpload': VBFileUpload,
  'VBDropdown': VBDropdown,
  "VBStepButton": VBStepButton,
  "VBPhoneInput": VBPhoneInput,
  "VBMultiLine": VBMultiLine,
  "VBDatePicker": VBDatePicker,
  "VBLoadingScreen": ConnecteVBLoadingScreen,
  "VBOldforestEditor": VBOldforestEditor,
  "VBErrorBoundary": VBErrorBoundary,
  "VBCalculateInterest": VBCalculateInterest,
  'Steps': ConnectedSteps,
  "Step1": ConnectedStep1,
  "Step2": ConnectedStep2,
  "Step3": ConnectedStep3,
  "Step4": ConnectedStep4,
  "Step5": ConnectedStep5,
  "AdLanding": ConnectedAdLanding,
  "VBAlert": VBAlert,
  "VBCarousel" : VBCarousel,
  "ProfileAdListings": ProfileAdListings,
  "Login": Login,
  "Complementinfo":Complementinfo,
  "PurchasePrimakund": PurchasePrimakund,
  "ForestCatalogue": ForestCatalogue,
  "PriceMap": PriceMap
}