import react, { useState, useEffect } from "react";
import BankContactPerson from "./bankContactPerson";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/vbDropdown";
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";

const ForestCatalogue = ({ static_path, munis }) => {
  const [municipalitySelected, setMunicipalitySelected] = useState();
  const [persons, setPersons] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const styles = {
    searchContainer: {
      webkitBoxShadow: "17px 16px 32px 6px rgba(158,158,158,0.74)",
      boxShadow: "17px 16px 32px 6px rgba(158,158,158,0.74)",
      border: "none",
      marginTop: "4.3rem",
      height: "270px",
      backgroundColor: "#eeeeee",
    },
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `/forest-catalogue-api/${municipalitySelected.muniValue}`
      );
      if (response.ok) {
        let data = await response.json();
        setPersons(data);
        setIsLoading(false);
        $("body").animate(
          {
            scrollTop: $(".card-container").offset().top,
          },
          "slow"
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div
        className="container-fluid bg-white pb-5 m-0 p-0"
        id="forest-catelog"
      >
        <div className="container bg-white pt-5 pb-5">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <h1 className="fw-bolder big blue">Hitta skogsbank</h1>
              <h2 className="text-muted pt-4" style={{"fontSize": "1.5rem"}}>
                Höj lönsamheten i ditt skogsbruk med rätt bank
              </h2>
              <p className="pt-3">
                Hos bankerna finns lång erfarenhet och mycket kompetens om skog
                som kan hjälpa dig höja lönsamheten i ditt skogsbruk. Hitta rätt
                bankkontakt genom att välja kommun och klicka på ”hitta
                bankkontakt”
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3 d-flex justify-content-center align-items-center">
              <div className="col-10">
                <div className={`card`} style={styles.searchContainer}>
                  <div className="my-auto mx-auto">
                    <div className="px-3 w-100">
                      <VBDropdown
                        errors={{}}
                        valueChange={(value, label) => {
                          setMunicipalitySelected({
                            muniValue: value,
                            muniName: label,
                          });
                        }}
                        ad_data={{}}
                        options={munis}
                        name="muniValue"
                        title="Välj kommun"
                        disable_ad_data={true}
                      />
                    </div>
                    <div className="mt-4 px-5">
                      <button
                        className="btn btn-blue btn-fill py-1"
                        type="button"
                        onClick={getData}
                        disabled={!municipalitySelected}
                      >
                        Hitta bankkontakt
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact person Card component */}
      {isLoading ? (
        <VBLoadingScreen show_loading={isLoading} />
      ) : (
        <div>
          {persons ? (
            <div className="container-fluid m-0 pb-3 p-0 pt-3 bg-whiteoff">
              <BankContactPerson person={persons} static_path={static_path} />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ForestCatalogue;
