import { Provider, connect } from "react-redux";

import CommonComponent from "@virkesborsen/vb-js-commons/lib/components/CommonComponent";
import VBInput from "@virkesborsen/vb-js-commons/lib/components/vbInput";
import VBRadios from "@virkesborsen/vb-js-commons/lib/components/vbRadios";
import VBRadio from "@virkesborsen/vb-js-commons/lib/components/vbRadio";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/vbMultiOption";
import VBMultiInput from "@virkesborsen/vb-js-commons/lib/components/vbMultiInput";
import VBForestMapFinalfellingApplicationChooser from "@virkesborsen/vb-js-commons/lib/components/vbForestMapFinalfellingApplicationChooser";
import VBForestMap from "@virkesborsen/vb-js-commons/lib/components/vbForestMap";
import VBForestCreator from "@virkesborsen/vb-js-commons/lib/components/vbForestCreator";
import VBFileUpload from "@virkesborsen/vb-js-commons/lib/components/vbFileUpload";
import VBDropdown from "@virkesborsen/vb-js-commons/lib/components/vbDropdown";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/vbPhoneInput";
import VBMultiLine from "@virkesborsen/vb-js-commons/lib/components/vbMultiLine";
import VBDatePicker from "@virkesborsen/vb-js-commons/lib/components/vbDatePicker";
import VBOldforestEditor from "@virkesborsen/vb-js-commons/lib/components/vbOldForestEditor";
import VBStepButton from "@virkesborsen/vb-js-commons/lib/components/vbStepButton";
import VBAlert from "@virkesborsen/vb-js-commons/lib/components/vbAlert";

const ConnectedVBInput = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBInput);

const ConnectedVBRadios = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBRadios);

const ConnectedVBRadio = connect(
    state => ({}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBRadio);

const ConnectedVBMultiOption= connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBMultiOption);

const ConnectedVBMultiInput = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBMultiInput);

const ConnectedVBForestMapFinalfellingApplicationChooser = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden'], municipality: state.municipality.municipality, municipality_area: state.municipality_area.municipality_area}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        update_ad_data: (data) => dispatch({type: "UPDATE_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBForestMapFinalfellingApplicationChooser);

const ConnectedVBForestMap = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBForestMap);

const ConnectedVBForestCreator = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        store_ad_data: (data) => dispatch({ type: "STORE_AD_DATA", payload: data }),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBForestCreator);

const ConnectedVBFileUpload = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBFileUpload);

const ConnectedVBDropdown = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBDropdown);

const ConnectedVBPhoneInput = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBPhoneInput);

const ConnectedVBMultiLine = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBMultiLine);

const ConnectedVBDatePicker = connect(
    state => ({errors: state.errors.errors, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
        addError: (data) => dispatch({type: 'ERROR', payload: data}),
        removeError: (name) => dispatch({type: "REMOVE_ERROR", payload: name}),
        send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    }),
    null,
    {forwardRef: true}
)(VBDatePicker);

const ConnectedVBStepButton= connect(
    state => ({init_load: state.init_load.init_load, step:state.step.step, hidden: state.interactions.interactions['hidden']}),
    dispatch => ({
      change_step: (step) => dispatch({type: "STEP_CHANGE", payload:step}),
      send_ad_data: (data) => dispatch({type: "SEND_AD_DATA", payload:data}),
      setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    })
  )(VBStepButton);

  const ConnectedVBAlert= connect(
    state => ({alertMessage: state.alertMessage.alertMessage}),
    dispatch => ({
        setAlert: (txt) => dispatch({type: "SET_ALERT", payload: txt})
    })
  )(VBAlert);
  

export {
    CommonComponent as CommonComponent,
    ConnectedVBInput as VBInput,
    ConnectedVBRadios as VBRadios,
    ConnectedVBRadio as VBRadio,
    ConnectedVBMultiOption as VBMultiOption,
    ConnectedVBMultiInput as VBMultiInput,
    ConnectedVBForestMapFinalfellingApplicationChooser as VBForestMapFinalfellingApplicationChooser,
    ConnectedVBForestMap as VBForestMap,
    ConnectedVBForestCreator as VBForestCreator,
    ConnectedVBFileUpload as VBFileUpload,
    ConnectedVBDropdown as VBDropdown,
    ConnectedVBPhoneInput as VBPhoneInput,
    ConnectedVBMultiLine as VBMultiLine,
    ConnectedVBDatePicker as VBDatePicker,
    VBOldforestEditor as VBOldforestEditor,
    ConnectedVBStepButton as VBStepButton,
    ConnectedVBAlert as VBAlert,
}