import React from 'react';
import {VBRadios} from '../connect';
import {VBDropdown} from '../connect';
import {VBForestMapFinalfellingApplicationChooser} from '../connect';
import {VBStepButton} from '../connect';

class Step2 extends React.Component {
    constructor(){
        super();
        this.state={
            "seen_delete_notice": false
        }
        this.map = React.createRef();
    }

    render() {
        if(this.props.init_load){
            return null;
        } else{
            return <div class="step-section" data-step="2" style={this.props.step == 2 ? {"display":"block"} : {"display":"none"}}>
            <div class="input-grouping" style={this.props.hidden.indexOf("felling_application_responsible") != -1 ? {"display":"none"} : {"display":""}}>
                <div class="inputs">
                    <VBRadios ad_data={this.props.ad_data} hidden={this.props.hidden} name="felling_application_responsible" title="Vem är ansvarig för avverkningsanmälan?" options={
                        [
                            {
                                "id": "seller",
                                "label": "Jag"
                            },
                            {
                                "id": "buyer",
                                "label": "Köparen"
                            },
                            {
                                "id": "exists",
                                "label": "Finns Redan"
                            }
                            ]
                    } 
                    larger={true}
                    />
                    
                </div>
                <div class="inputs-info">
                    <p>
                    <b>Jag:</b> Du som skogsägare utför själv avverkningsanmälan via skogsstyrelsen.<br/><br/>
                    <b>Köparen:</b> Personen som du eventuellt väljer som köpare upprättar avverkningsanmälan åt dig.<br/><br/>
                    <b>Finns redan:</b> Om en avverkningsanmälan redan finns väljer du detta alternativ.
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
            <div class="input-grouping">
                <div class="inputs">
                    <h3>Kommun och Karta</h3>
                    <VBDropdown 
                    name="muni" 
                    title="I vilken kommun ligger skogen?" 
                    options={this.props.municipalities} 
                    ad_data={this.props.ad_data}
                    onFocus={(event) => {
                        if(this.props.ad_data.map_data && !this.state.seen_delete_notice){
                            this.props.setAlert("Om du ändrar kommun kommer det du markerat i kartan raderas!");
                            this.setState({
                                "seen_delete_notice": true
                            });
                        }
                    }}
                    valueChange={
                        (id, name) => {
                        this.props.set_muni(id, name);
                    }
                }
                    />
                    <VBForestMapFinalfellingApplicationChooser 
                        hidden={this.props.hidden}
                        ad_data={this.props.ad_data} 
                        toggle_loading={this.props.toggle_loading}
                        ref={this.map}
                        reZoom={this.props.step == 2}
                        initialCenter={{ "lat": 62.3875, "lng": 16.325556}} 
                    />
                    
                </div>
                <div class="inputs-info">
                    <p>
                    <b>Kommun:</b> Välj i vilken kommun din skog står i.<br/><br/>
                    <b>Placera markör:</b> Här kan du placera en markör över området där skogen finns eller för att markera avlägg.<br/><br/>
                    <b>Hämta avverkningsanmälningar:</b> Om du redan har avverkningsanmälan klickar du på hämta avverkningsanmälningar, zoomar in i kartan och väljer den/dom avverkningsanmälningarna för den aktuella annonsen.<br/><br/>
                    <b>Rita manuellt:</b> Här kan du själv rita in området som skall avverkas eller gallras. Du kan rita in flera områden direkt.<br/><br/>
                    <b>Ladda upp shape:</b> Om du har en eller flera shape-filer på området som skall avverkas kan du ladda upp dem här (En fil åt gången). Om du har separata .shp, .dbf, .prj, .cpg, .shx filer slå ihop dom till en .zip fil och ladda upp den.<br/><br/>
                    <b>Rensa markeringar:</b> Om du gjort fel kan du klicka på ”Rensa markeringar” för att tömma kartan.
                    </p>
                    <span className="hide-info-mobile btn blue large" onClick={(event) => {event.target.parentElement.style.cssText=""}}>Stäng infobox</span>
                </div>
                <span className="show-info-mobile" onClick={(event) => {event.target.parentElement.querySelector(".inputs-info").style.cssText="display:block"}}></span>
            </div>
            <div class="btn-container">
                <div class="left">
                    <VBStepButton text="Föregående" increment={-1} />
                </div>
                <div class="right">
                    <button onClick={() => this.props.visible_ad_draft()} class="btn blue large dash">Spara utkast</button>
                    <VBStepButton text="Nästa" increment={1} />
                </div>
            </div>
        </div>;
    }
    }
}

export default Step2;