import React from 'react';
import VBLoadingScreen from "@virkesborsen/vb-js-commons/lib/components/vbLoadingScreen";
import Cookies from 'js-cookie';

class PurchasePrimakund extends React.Component {
    constructor(){
        super();

        let url = new URL(window.location);
        

        let cc_code = "";
        let sub_length = "none";


        let cc_code_url = url.searchParams.get("coupon_code");
        let sub_length_url = url.searchParams.get("subscription_length");
        if(cc_code_url){
            cc_code = cc_code_url;
        }

        if(sub_length_url){
            sub_length = sub_length_url;
        }

        this.state={
            "klarna_html": null,
            "loading": false,
            "subscription_length": 'year',
            "coupon_code": cc_code,
            "errors": {},
            "inital_load": true,
            "show_discount_input": !url.searchParams.get("coupon_code")
        }

    }

    getKlarnaCheckout(){
        gtag('event', 'InitiateCheckout', {});
        gtag('event', 'begin_checkout', {
            "currency":"sek",
            "value": 0,
            "coupon": this.state.coupon_code,
            "items": [
                {
                    "item_id": this.state.subscription_length,
                    "item_name": this.state.subscription_length
                }
            ]
        });

        const headers = {
            'Content-Type': 'application/json',

        }
        let url = "/primakund/klarna/";
        url = url + "?subscription_length=" + this.state.subscription_length;
        if(this.state.coupon_code){
            url = url + "&coupon_code=" + this.state.coupon_code;
        }
        

        this.setState({
            "loading":true
        });

        fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: headers
        })
        .then(resp => resp.json())
        .then((json) => {
            var checkoutContainer = document.getElementById('klarna_container');
            if(json.errors){
                this.setState({
                    "errors":json.errors,
                    "loading":false
                });
                checkoutContainer.innerHTML = "";
            } else{
                checkoutContainer.innerHTML = json.klarna_html;
                var scriptsTags = checkoutContainer.getElementsByTagName('script');
                // This is necessary otherwise the scripts tags are not going to be evaluated
                for (var i = 0; i < scriptsTags.length; i++) {
                    var parentNode = scriptsTags[i].parentNode;
                    var newScriptTag = document.createElement('script');
                    newScriptTag.type = 'text/javascript';
                    newScriptTag.text = scriptsTags[i].text;
                    parentNode.removeChild(scriptsTags[i]);
                    parentNode.appendChild(newScriptTag);
                }
                this.setState({
                    "loading":false,
                    "errors":{}
                });
            }
        });
    }

    formSubmit(e){
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        this.getKlarnaCheckout();
    }


    render() {
    let show_checkout_button = true;
    let show_discount_input = this.state.show_discount_input;
    let url = new URL(window.location);

    if(this.state.errors.coupon_code){
        show_discount_input = true;
    }

    if(url.searchParams.get("instant_generate") && !show_discount_input){
        show_checkout_button = false;
    }

    if(this.state.inital_load){
        
        
        if(url.searchParams.get("instant_generate")){
            this.getKlarnaCheckout();
        }
        this.setState({
            "inital_load": false
        });
    }
        
    return <React.Fragment>
        <VBLoadingScreen show_loading={this.state.loading} />
        <form className="vb-form mb-4" id="buy-ad-form" onSubmit={this.formSubmit.bind(this)} data-track="true" data-eventName="Purchase Prima" data-eventData="{}">
            <div className="form-controls">
                {this.props.next ? 
                <input type="hidden" name="next" value={this.props.next}/>
                :null}
                <div className={this.state.errors.coupon_code ? "error-container" : ""}>
                    <p style={{"display":show_discount_input ? "inital": "none"}}>Eventuell rabattkod</p>
                    <input value={this.state.coupon_code} onChange={
                        (e)=>{
                            this.setState({"coupon_code": e.target.value});
                            var checkoutContainer = document.getElementById('klarna_container');
                            checkoutContainer.innerHTML = "";
                            }
                        } type={show_discount_input ? "text" : "hidden"} name="coupon_code" />
                    <span className="error">{this.state.errors.coupon_code}</span>
                </div>
            </div> 
            
            {show_checkout_button ? <button className="btn btn-blue" type="submit">Gå till betalning</button> : null}
        </form>

        {this.state.coupon_code == "PRIMASEB" ? <p>SEB bjuder dig som använder denna rabattkod på gratis primakund dom tre första månaderna. På grund av tekniska begränsningar så kommer det se ut som att du endast fick 1 månad gratis fram tills 2021-06-23 och sen kommer du se den fullständiga längden</p> :null}
        <div className="bg-white box-shadow bs-light" id="klarna_container"></div>
    </React.Fragment>;
    }
}

export default PurchasePrimakund;