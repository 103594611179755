import React from 'react';
import VBInput from "@virkesborsen/vb-js-commons/lib/components/vbInput";
import VBRadios from "@virkesborsen/vb-js-commons/lib/components/vbRadios";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/vbPhoneInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/vbMultiOption";
import VBAlert from "@virkesborsen/vb-js-commons/lib/components/vbAlert";
import {get_ad_data} from "@virkesborsen/vb-js-commons/lib/api";
import Cookies from 'js-cookie';

class AdLanding extends React.Component {
    constructor(){
        super();

        this.state={
            isReg: null,
            alertMsg: "",
            showPopup: false
        }
        this.checkingEmail = null;
        this.emailInput = React.createRef();
    }
    
    componentDidMount(){
        if(this.props.formData.hasOwnProperty("email")){
            if(this.props.formData['email'] && !this.props.isloggIn){
                this.checkEmail(this.props.formData['email']);
            }
        }
        if(Cookies.get("ad_id") && this.props.isloggIn){
            let ad_resp = get_ad_data();
            ad_resp.then(resp => resp.json())
            .then((ad_json) => {
                if(ad_json.state <= 20){
                    this.setState({
                        showPopup: true
                    });
                } else{
                    Cookies.remove("ad_id");
                }
            });
        }
        
    }

    checkEmail(email){
        if(this.emailInput.current.validation(email)){
            const headers = {
                'Content-Type': 'application/json',
                'X-CSRFToken': Window.csrfToken
            }
            
            //clearTimeout(this.checkingEmail);
            this.checkingEmail = setTimeout(function(){
                fetch("/user-api/exists/?email=" + encodeURIComponent(email), {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: headers
                })
                .then(resp => resp.json())
                .then((json) => {
                    this.setState({
                        'isReg': json.exists
                    });
                });
            }.bind(this), 200);
        }
    }

    componentWillUnmount(){
        clearTimeout(this.checkingEmail);
    }

    sendToStartedAd(event){
        let ad_resp = get_ad_data();
        ad_resp.then(resp => resp.json())
        .then((ad_json) => {
            Cookies.remove("curr_step"); 
            mixpanel.track("Resume old ad", {"ad_id": ad_json.id});
            //check where to send user
            if(ad_json.state == 10){
                window.location = "/auctions/create/"; 
            } else if(ad_json.state == 20){
                window.location = "/auctions/" + ad_json.id.toString() + "/review/"; 
            } else{
                alert("Oj då, vi något gick fel när vi skulle skicka dig till annonsen. Testa gå till annonsen genom mina sidor");
            }
        });   
    }

    closePopup(event){
        event.target.parentElement.parentElement.style.cssText="display:none"; 
        event.preventDefault();
    }

    render() {
    return <div style={{position: "relative"}} onClick={(event) => {
        let event_name = event.target.getAttribute("name");
        if(typeof event_name !== 'undefined' && event_name !== null){
            let event_name_no_numbers = event_name.replace(/\d+/g,'');
            event_name = event_name_no_numbers.replace("-",'');
            mixpanel.track("Ad form", {"clicked": event_name});
        }
        }}>
                {this.props.errorMsg != "None" ? <h2 class="error">{this.props.errorMsg}</h2> : null}
                <VBRadios 
                    name="arrangement"
                    disable_ad_data={true}
                    title="Vad vill du göra?"
                    helptText="Välj hur du vill ta in anbuden"
                    options={this.props.arrangement}
                    ad_data={this.props.formData}
                    errors={this.props.formErrors}
                    larger={true}
                    title_append={<span className="show-info" onClick={() => {
                        //let msg = <p><b>Vad vill du göra:</b> Här anger du om du ska slutavverka, gallra eller utföra en övrig avverkning (t.ex. vindfällen)<br/><br/>
                        //<b>Fastighetsbeteckning:</b> Här anger du fastighetsbeteckningen eller fastighetsbeteckningarna där åtgärden skall utföras. (t.ex. Åbo 1:1)</p>;
                        let msg = <p><b>Vad vill du göra:</b> Här anger du om du ska slutavverka, gallra eller utföra en övrig avverkning (t.ex. vindfällen)</p>;
                        this.setState({alertMsg: msg});
                        }}
                        ></span>}
                />
                {/* 
                <VBInput 
                    errors={this.props.formErrors} 
                    inputType="text" 
                    name="property_designation" 
                    ad_data={this.props.formData} 
                    title="Fastighetsbeteckning" 
                    disable_ad_data={true} 
                    larger={true} 
                />
                */}
                {!this.props.isloggIn ? 
                <VBInput 
                    errors={this.props.formErrors} 
                    onChangeFunc={this.checkEmail.bind(this)} 
                    ad_data={this.props.formData} 
                    inputType="email" 
                    name="email" 
                    title="E-postadress" 
                    disable_ad_data={true} 
                    ref={this.emailInput}
                    larger={true} 
                    />
                :null}
                <div>
                    {this.state.isReg === false ? 
                        <VBMultiOption errors={this.props.formErrors} name="muni" ad_data={this.props.formData} title="I vilka kommun(er) äger du skog? (flerval)" options={this.props.munis} disable_ad_data={true} larger={true} />
                    :null}
                    {this.state.isReg === false ? 
                        <VBPhoneInput errors={this.props.formErrors} name="phonenumber" ad_data={this.props.formData} title="Mobilnummer" disable_ad_data={true} />
                    :null}
                    <div class="input-group">
                        {!this.props.isloggIn ? 
                            <div class="input-label">
                                <VBInput errors={this.props.formErrors} inputType="password" ad_data={this.props.formData} name="password1" disable_ad_data={true} title={!this.state.isReg ? "Välj lösenord (8+ tecken)" : "Lösenord"} larger={true}/>
                            </div>
                        : null}
                        {this.state.isReg && !this.props.isLoggIn ?
                            <a style={{fontSize: "16px", width: "100%"}} href="/forgot-password/">Glömt lösenord?</a>
                        : null}
                        {this.state.isReg === false ? 
                            <div class="input-label">
                                <VBInput errors={this.props.formErrors} inputType="password" ad_data={this.props.formData} name="password2" disable_ad_data={true} title="Upprepa lösenordet" larger={true}/>
                            </div>
                        :null}
                        </div>
                </div>

                {this.state.showPopup && this.props.isloggIn && Object.keys(this.props.formErrors).length === 0 && this.props.formErrors.constructor === Object ? 
                    <div className="popup-startedAd">
                        <div>
                            <h2>Du har en påbörjad annons</h2>
                            <p>Vill du fortsätta på den annonsen eller skapa en ny? Du kan även se alla din annonsutkast under <a href="/my-account/listings/">mina sidor</a></p>
                            <button type="button" className="btn orange large" onClick={this.closePopup}>Skapa ny annons</button>
                            <button type="button" className="btn blue large" onClick={this.sendToStartedAd}>Fortsätt på påbörjad annons</button>
                            <span onClick={this.closePopup}>×</span>
                        </div>
                    </div> 
                : null}
                <VBAlert 
                setAlert={(txt) => {this.setState({
                    alertMsg: txt
                });}}
                alertMessage={this.state.alertMsg} />
        </div>;
    }
}

export default AdLanding;