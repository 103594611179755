import React from 'react';
import VBInput from "@virkesborsen/vb-js-commons/lib/components/vbInput";
import VBRadios from "@virkesborsen/vb-js-commons/lib/components/vbRadios";
import VBPhoneInput from "@virkesborsen/vb-js-commons/lib/components/vbPhoneInput";
import VBMultiOption from "@virkesborsen/vb-js-commons/lib/components/vbMultiOption";
import VBAlert from "@virkesborsen/vb-js-commons/lib/components/vbAlert";
import {get_ad_data} from "@virkesborsen/vb-js-commons/lib/api";
import BuyerSellerBoth from "@virkesborsen/vb-js-commons/lib/components/buyerSellerBoth"
import Cookies from 'js-cookie';

class complementinfo extends React.Component {
    constructor(){
        super();
        this.state={
            alertMsg: "",
            forestRegionLabel:"I vilka kommun(er) äger du skog? (flerval)"
        }
        this.buyerSellerBoth = React.createRef();

    }

    componentDidMount(){

        // If the User has Role - Display the Text according to the Role
        if (this.props.role_id == 2){
            this.setState({forestRegionLabel:"I vilka kommun(er) äger du skog? (flerval)"});    
        } else if (this.props.role_id == 1){
            this.setState({forestRegionLabel:"I vilka kommun(er) köper du skog? (flerval)"});    
        } else if(this.props.role_id == 3){
            this.setState({forestRegionLabel:"I vilka kommun(er) är du verksam? (flerval)"});
        } 
    

    }

    // If the User dont have role choose the Display Text according to the selection.

    changeActiveRegionLabel (buyerSellerBoth, checked) {
        if(checked){
            if(buyerSellerBoth.indexOf("seller") != -1){
                this.setState({forestRegionLabel:"I vilka kommun(er) äger du skog? (flerval)"});
            } else if(buyerSellerBoth.indexOf("buyer") != -1){
                this.setState({forestRegionLabel:"I vilka kommun(er) köper du skog? (flerval)"});
            } else{
                this.setState({forestRegionLabel:"I vilka kommun(er) är du verksam? (flerval)"});
            }
        }
    }
  
    render() {
        return <div>
        {/* Choose Roles for user if they dont have any */}
            {!this.props.user_has_roles ? <div>
            <BuyerSellerBoth 
                data={this.props.buyerSellerBothData}
                formData={this.props.chosen_roles ? {"roles": this.props.chosen_roles} : {}}
                errors={this.props.formErrors}
                ref={this.buyerSellerBoth}
                changeActiveRegionLabel={this.changeActiveRegionLabel.bind(this)}
               />
            </div> : null }
            {/* Choose Muni for user if they dont have any */}
            {!this.props.user_has_muni ? <div>
            <VBMultiOption 
                    errors={this.props.formErrors} 
                    name="muni" 
                    ad_data={this.props.chosen_muni_ids ? {"muni": this.props.chosen_muni_ids} : {}} 
                    title={this.state.forestRegionLabel}
                    options={this.props.munis} 
                    disable_ad_data={true} 
                    larger={true} />
            </div> : null }
            {/* Enter Telephone Number for user if they dont have any */}
            {!this.props.user_has_phone ? <div>
            <VBPhoneInput 
                    errors={this.props.formErrors}
                    name="mobile_phone_number" 
                    ad_data={this.props.formData} 
                    title="Mobilnummer" 
                    larger={true}
                    disable_ad_data={true} />
             </div> : null}
            
             <button class="btn white right" type="submit">Fortsätt</button>


            </div>;
    }
}
export default complementinfo;